.form-group.required .form-control-label::before {
  content: "*";
  color: #c00; }

.login-page {
  margin-top: 1em; }
  .login-page .login-form-nav {
    margin-bottom: 1.25em; }
    .login-page .login-form-nav .tab-pane {
      margin-top: 1em; }
  .login-page .login {
    margin-bottom: 0.938em; }
    .login-page .login .error-message-form {
      padding: 0.625em;
      background-color: #c00;
      color: #fff;
      border-radius: 0.188em; }

.registration-page .privacy-policy {
  margin-top: 1rem; }

.login-banner {
  background-image: url("../images/account.jpg");
  background-position-y: 40%; }

.equal-height .card {
  width: 100%; }

.track-order-header {
  font-size: 1.75rem; }

.request-password-title {
  font-size: 1.5rem; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.request-password-title {
  font-size: inherit; }

.privacy-policy {
  margin-top: 1rem; }
